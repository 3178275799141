import * as React from "react";
import styled from "styled-components";
import { Carousel } from "../../components/atoms/carousel";
import { Text } from "../../components/atoms/typography";
import { Theme } from "../../components/core/app.theme";
import { ResponsiveLayout } from "../../components/gls/responsive-layout";
import { VerticalSpacer } from "../../components/gls/spacer";
import {
  PartnerAtlasCopco,
  PartnerClaytonH2,
  PartnerConvolo,
  PartnerFoton, PartnerLogoAusmasa,
  PartnerLogoGreenHydrogen,
  PartnerLogoHScaled,
  PartnerLogoIndro,
  PartnerLogoTfa,
  PartnerLogoVoltFarmers,
  PartnerNera
} from "../../components/templates/common/partner-logo";
import { Section } from "../../components/templates/page/section/section";

export const FooterPartnersSection = () => {
  return (
    <Section maxWidth={"full"} py={"xl"}>
      <HeadingContainer>
        <Text fontSize={"md"} fontFamily={"monument"} fontWeight={"book"} textTransform={"uppercase"}>
          We Are Proud To Be Associated With:
        </Text>
      </HeadingContainer>
      <VerticalSpacer />
      <ResponsiveLayout
        mobile={() => (
          <Carousel
            items={items}
            autoPlay
            autoPlayInterval={2000}
            responsive={{
              0: {
                items: 1,
              },
            }}
            infinite
            disableButtonsControls
            touchTracking
          />
        )}
        desktop={() => (
          <Carousel
            items={items}
            autoPlay
            autoPlayInterval={2000}
            autoPlayStrategy={"none"}
            responsive={{
              0: {
                items: 1,
              },
              500: {
                items: 5,
              },
            }}
            infinite
            autoWidth
            disableButtonsControls
            disableDotsControls
          />
        )}
      />
    </Section>
  );
};

const CarouselItem = styled.div({
  textAlign: "center",
  [Theme.media("lg")]: {
    width: "350px",
  },
});

const items = [

  <CarouselItem>
    <PartnerLogoGreenHydrogen />
  </CarouselItem>,
  <CarouselItem>
    <PartnerNera />
  </CarouselItem>,
  <CarouselItem>
    <PartnerLogoVoltFarmers />
  </CarouselItem>,
  <CarouselItem>
    <PartnerLogoHScaled />
  </CarouselItem>,
  <CarouselItem>
    <PartnerLogoIndro />
  </CarouselItem>,
  <CarouselItem>
    <PartnerLogoTfa />
  </CarouselItem>,
  <CarouselItem>
    <PartnerAtlasCopco />
  </CarouselItem>,
  <CarouselItem>
    <PartnerFoton />
  </CarouselItem>,
  <CarouselItem>
    <PartnerConvolo />
  </CarouselItem>,
  <CarouselItem>
    <PartnerClaytonH2 />
  </CarouselItem>,
  <CarouselItem>
    <PartnerLogoAusmasa/>
  </CarouselItem>,
];

const HeadingContainer = styled.div({
  margin: "0 auto",
  maxWidth: Theme.sizeContainer("lg"),
  padding: `${Theme.space("md")}`,
});
